<template>
  <!-- 最近上新 -->
  <div class="hot-material">
    <div class="hot-material-header">
      <div class="hot-material-title">最近上新</div>
      <!-- <div class="tool-box">
        <van-button
          type="primary"
          size="mini"
          color="rgba(0,0,0,0.65)"
          plain
          @click="shareTest"
          :icon="require('@/assets/img/common/tool.svg')"
        >
          测试分享
        </van-button>
      </div> -->
      <div class="tool-box">
        <van-button
          type="primary"
          size="mini"
          color="rgba(0,0,0,0.65)"
          plain
          :icon="require('@/assets/img/common/tool.svg')"
          :to="'/tool'"
        >
          工具
        </van-button>
      </div>
    </div>
    <hot-material-item
      v-for="(hotItem, idx) in hotMaterials"
      :key="idx"
      :data="hotItem"
      @share="handleShare(hotItem)"
    />
  </div>
  <!-- 呼出分享面板 -->
  <van-action-sheet
    v-model:show="showShare"
    :actions="shareActions"
    cancel-text="取消"
    @select="handleShareSelect"
  />
</template>

<script>
import { shareToExternalContactNew } from '../../utils/nwechat'
import { Button, Notify, ActionSheet } from 'vant'
import { getHotMaterial } from '@/service/material.service'
import { shareType } from '@/utils/constants'
import HotMaterialItem from './_hot-material-item.vue'

// 根据 lable 返回对应的 key
const findShareKey = (label) => {
  for (const key in shareType) {
    if (shareType[key].label === label) {
      return key
    }
  }
}

export default {
  name: 'Workbench',
  components: {
    HotMaterialItem,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet
  },
  data() {
    const _share = Object.values(shareType)

    return {
      showShare: false,
      shareActions: Array.from(_share, ({ label }) => ({ name: label })),
      hotMaterials: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.initHotMaterial()
    })
  },
  methods: {
    // 初始化最近上新数据
    async initHotMaterial() {
      try {
        this.hotMaterials = await getHotMaterial()
      } catch (e) {
        Notify({ type: 'danger', message: '最近上线数据获取失败！' })
      }
    },
    //  测试分享
    // shareTest() {
    //   let textParams = {
    //     content: '12345789'
    //   }
    //   let fileParamsArr = [
    //     {
    //       msgtype: 'image', // 消息类型，必填
    //       image: {
    //         mediaid: '3gcy-zz9UClWrbVKhu8g1mfGjF5okUVPPC1MAB5KBaDU', // 图片的素材id
    //         imgUrl:
    //           'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/49e02212ff73c54e961766b6691f388a.jpg' // 图片的imgUrl,跟图片mediaid填其中一个即可
    //       }
    //     },
    //     {
    //       msgtype: 'link', // 消息类型，必填
    //       link: {
    //         title: '测试网页', // H5消息标题
    //         imgUrl:
    //           'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/49e02212ff73c54e961766b6691f388a.jpg', // H5消息封面图片URL
    //         desc: 'dffd附近的舒服了靠的就是', // H5消息摘要
    //         url: 'https://www.baidu.com/' // H5消息页面url 必填
    //       }
    //     },
    //     {
    //       msgtype: 'miniprogram', // 消息类型，必填
    //       miniprogram: {
    //         appid: 'wxbecd55f783f6a0cd', // 小程序的appid
    //         title: '这是一个小程序', // 小程序消息的title
    //         imgUrl:
    //           'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1e3221deef4cd592e01b503841015727.jpg', //小程序消息的封面图。必须带http或者https协议头
    //         page: 'pages/product/inde' //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    //       }
    //     },
    //     {
    //       msgtype: 'video', // 消息类型，必填
    //       video: {
    //         mediaid:
    //           '3YNjaZonbm9bH0Z_W1K0oHPgXr8QPTPxzzpsfXAI9axvlYQHBoeB4ppp4xjUEJ1bA' // 视频的素材id
    //       }
    //     },
    //     {
    //       msgtype: 'file', // 消息类型，必填，从3.1.12版本开始支持
    //       file: {
    //         mediaid:
    //           '34yyYybonMVpk9FsMGUekesQtG9i1vvNrrbygbJpEXLBJacVHy6udkn4jlg8rHnJR' // 文件的素材id，必填
    //       }
    //     }
    //   ]
    //   shareToExternalContactNew({ textParams, fileParamsArr })
    //     .then((res) => {
    //       console.log('1111111111111111', res)
    //     })
    //     .catch((e) => {
    //       console.log('111111111111111111111', e)
    //     })
    // },
    // 分享
    handleShare(hotItem) {
      this.showShare = true
      this.__cache = hotItem
    },
    // 分享类型选择
    handleShareSelect(typeItem) {
      this.$router.push({
        name: 'material-guide',
        params: { id: this.__cache.id },
        query: {
          type: findShareKey(typeItem.name)
        }
      })
      this.showShare = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.hot-material {
  margin: 8px;
  background: @white;
  border-radius: 5px;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  & &-title {
    display: inline-block;
    margin: 8px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid @blue;
  }

  .tool-box {
    display: flex;
    align-items: center;
    margin-right: 9px;

    // .van-button--mini {
    //   height: 24px;
    // }
  }
}
</style>
<style lang="less">
.tool-box .van-button--mini {
  padding: 0 8px;
  height: 24px;
  .van-button__text {
    font-size: 14px;
    line-height: 16px;
  }
  .van-button__icon {
    font-size: 1rem;
    .van-icon__image {
      margin-top: 3px;
    }
  }
  .van-button__icon + .van-button__text {
    margin-left: 8px;
  }
}
</style>
