import { Toast } from 'vant'

export const isArray = (target) => {
  return toString.call(target) === '[object Array]'
}
export const isUndefined = (target) => {
  return toString.call(target) === '[object Undefined]'
}
export const isObject = (target) => {
  return toString.call(target) === '[object Object]'
}
export const isPromise = (target) => {
  return toString.call(target) === '[object Promise]'
}
export const isString = (target) => {
  return toString.call(target) === '[object String]'
}
export const isEmpty = (target) => {
  if (isUndefined(target)) {
    return true
  } else if (isString(target)) {
    return target.trim().length === 0
  } else if (isObject(target)) {
    return Object.keys(target).length === 0
  } else if (isArray(target)) {
    return target.length === 0
  }
  return false
}
// 对Object/Array的数据判断当字符串、数组、对象为空时不传给后端
export const ignoreEmptyParams = (data) => {
  if (!data) return data
  let copyData = {}
  Object.keys(data).forEach((key) => {
    if (!isEmpty(data[key])) {
      // 为空
      copyData[key] = data[key]
    }
  })
  return copyData
}

// 日期格式化
export function formatDate(date, fmt) {
  if (
    typeof fmt !== 'string' ||
    fmt === '' ||
    fmt === null ||
    fmt === undefined
  )
    return date.toString()

  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }

  // 年
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length) // 如果两位补0
      )
    }
  }
  return fmt
}

// 关键词飘红
export function contentToRed(keyStr, content) {
  keyStr = keyStr + ''
  content = content + ''

  // 清除前一次的飘红
  content = content.replace(/<span class=\"search-txt-red\">/g, '')
  content = content.replace(/<\/span>/g, '')
  // console.log('清除后', content)
  let keyHtml = `<span class="search-txt-red">${keyStr}</span>`
  let arr = content.split(keyStr)
  let endContent = arr.join(keyHtml)
  return endContent
}

export function heightLight(keyword, string) {
  var reg = new RegExp(keyword, 'gi')
  string = string.replace(reg, function (txt) {
    return "<span class='search-txt-red'>" + txt + '</span>'
  })
  return string
}

// 关键词组偏红
export function heightLightArr(keywordList, string) {
  let keywordreg = keywordList.join('|\\')
  var reg = new RegExp('\\' + keywordreg, 'gi')
  string = string.replace(reg, function (txt) {
    return "<span class='search-txt-red'>" + txt + '</span>'
  })
  return string
}

/**
 *
 * 复制函数
 * @param data 要复制的内容
 *
 * */
export function copy(data) {
  let url = data
  let oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  Toast('复制内容成功')
  oInput.remove()
}

// 获取用户性别图标
export const getUserSexIcon = (type) => {
  const man = ['男', 'man', '1', 1]
  const women = ['女', 'woman', '2', 2]

  if (!type) return ''
  if (man.includes(type)) {
    return require('../assets/img/common/man.png')
  } else if (women.includes(type)) {
    return require('../assets/img/common/women.png')
  } else {
    return ''
  }
}

// 处理分享小程序参数
// 处理小程序路径加上分配所需参数
export const formatParamsDistribution = (
  path,
  customer_id,
  chart_id,
  source
) => {
  let obj = {
    member_id: localStorage.getItem('member_id') || '',
    customer_id: customer_id || '',
    chart_id: chart_id || '',
    source: source
  }
  let tempPath = path
  for (let key in obj) {
    if (obj[key]) {
      tempPath = `${tempPath}&${key}=` + obj[key]
    }
  }
  return Promise.resolve(tempPath)
}

/**
 *
 * @param {*} func 要进行debouce的函数
 * @param {*} wait 等待时间,默认500ms
 * @param {*} immediate 是否立即执行, 后续的调用不会在执行
 */

export const debounce = (func, wait = 500, immediate = false) => {
  var timeout
  return function () {
    var context = this
    var args = arguments

    if (timeout) clearTimeout(timeout)
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timeout
      timeout = setTimeout(function () {
        timeout = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args)
      }, wait)
    }
  }
}
