import {
  getWechatSignature,
  getWechatAgenSignature
} from '../service/wechat.service.js'
import { jsApiList, AgentId, corpid } from './constants'
import { checkGoodsStatus, usePosterRecord } from '@/service/quickSend.service'

import { Toast, Dialog } from 'vant'

const WX = window.wx // 实例
const ALLOW_URL = [] // 已经注册的 URL
const ALLOW_AGENT_URL = [] // 已经注册的应用 URL

// 所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用
// 同一个url仅需调用一次。
export async function WXRegister() {
  const url = location.href

  // 已经注册过了
  if (ALLOW_URL.includes(url)) return url
  let obj = {
    apis: jsApiList,
    open_tag_list: [],
    url,
    debug: true,
    beta: true,
    type: 'enterprise'
  }
  if (localStorage.getItem('chain_corp_id')) {
    obj.chain_corp_id = localStorage.getItem('chain_corp_id')
  }
  const res = await getWechatSignature(obj)

  const { appId, nonceStr, timestamp, signature } = res.data
  console.log('WXRegister', { appId, nonceStr, timestamp, signature })

  WX.config({
    beta: true, // 必须这么写，否则ww.invoke调用形式的jsapi会有问题
    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，企业微信的corpID
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  })

  return new Promise((resolve, reject) => {
    WX.ready(() => {
      // 添加到注册列表中
      if (!ALLOW_URL.includes(url)) ALLOW_URL.push(url)
      console.log(url + '#WXRegister success')
      console.log('ALLOW_URL: ', ALLOW_URL)
      resolve(url)
    })
    WX.error((e) => {
      console.log(url + '#WXRegister error:' + res)
      console.log('ALLOW_URL: ', ALLOW_URL)
      reject(e)
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/90548
export async function WXAgentRegister() {
  const url = location.href

  let chain_corp_id = localStorage.getItem('chain_corp_id')
  // 防止拿到的chain_corp_id为空的情况
  if (['undefined', 'null', undefined, null].includes(chain_corp_id)) {
    chain_corp_id = null
  }
  // 已经注册过了
  if (ALLOW_AGENT_URL.includes(url)) return url
  const res = await getWechatSignature({
    apis: jsApiList,
    open_tag_list: [],
    url: location.href,
    debug: true,
    beta: true,
    type: 'aggent',
    chain_corp_id
  })
  const { appId, agentid, nonceStr, signature, timestamp } = res.data
  console.log({ appId, agentid, nonceStr, signature, timestamp })
  console.log('agentconfig参数', {
    beta: true, // 必须这么写，否则ww.invoke调用形式的jsapi会有问题
    // debug: true, //
    corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    jsApiList: jsApiList //必填
  })

  return new Promise((resolve, reject) => {
    // console.log(1111111)
    WX.agentConfig({
      beta: true, // 必须这么写，否则ww.invoke调用形式的jsapi会有问题
      // debug: true, //
      corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
      agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
      timestamp: timestamp, // 必填，生成签名的时间戳
      nonceStr: nonceStr, // 必填，生成签名的随机串
      signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
      jsApiList: jsApiList, //必填
      success: function (res) {
        if (!ALLOW_AGENT_URL.includes(url)) ALLOW_AGENT_URL.push(url)
        console.log('#WXAgentRegister success')
        console.log('ALLOW_AGENT_URL:', ALLOW_AGENT_URL)
        resolve(url)
      },
      fail: function (e) {
        if (e.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级')
        }
        console.log('#WXAgentRegister fail')
        console.log('ALLOW_AGENT_URL:', ALLOW_AGENT_URL)
        console.log(e)
        reject(e)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/93555
// 将H5页面通过个人群发发送给客户
export async function shareToExternalContact({
  title = '',
  desc = '',
  link = '',
  imgUrl = ''
}) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    const data = {
      title, // 消息的标题
      desc, // 消息的描述
      link, // 消息链接
      imgUrl // 消息封面
    }

    WX.invoke('shareToExternalContact', data, (res) => {
      if (res.err_msg == 'shareToExternalContact:ok') {
        resolve(res)
      } else {
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/93555
// 将内容(多个内容同时发送)通过个人群发发送给客户（测试）
export async function shareToExternalContactNew({ textParams, fileParamsArr }) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    const data = {
      text: textParams,
      attachments: fileParamsArr
    }

    WX.invoke('shareToExternalContact', data, (res) => {
      console.log('群发结果------', res)
      if (res.err_msg == 'shareToExternalContact:ok') {
        resolve(res)
      } else {
        reject(res)
      }
    })
  })
}

// 创建企业互联/上下游会话
export async function createCorpGroupChat({
  groupName,
  userIds,
  openUserIds,
  externalUserIds,
  corpGroupUserIds
}) {
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    if (externalUserIds && typeof externalUserIds == 'string') {
      externalUserIds = externalUserIds.split(',') // 外部联系人id
    }
    let data = {
      groupName,
      userIds,
      openUserIds,
      externalUserIds,
      corpGroupUserIds
    }
    WX.invoke('createCorpGroupChat', data, (res) => {
      console.log('创建结果------', res)
      if (res.err_msg == 'createCorpGroupChat:ok') {
        resolve(res)
      } else {
        //
        // console.log('创建失败, 尝试小程序方式', e)
        // wx.qy.createCorpGroupChat({
        //   groupName,
        //   userIds,
        //   openUserIds,
        //   externalUserIds,
        //   corpGroupUserIds,
        //   success: function (e) {
        //     // 回调
        //     // var chatId = res.chatId;
        //     resolve(e.chatId)
        //   },
        //   fail: (e) => {
        //     console.log('创建失败', e)
        //   }
        // })
        // console.log('创建失败, 尝试小程序方式', e)
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/93563
// 将H5页面通过群发助手发送给客户群
export async function shareToExternalChat({
  title = '',
  desc = '',
  link = '',
  imgUrl = ''
}) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    const data = {
      title, // 消息的标题
      desc, // 消息的描述
      link, // 消息链接
      imgUrl // 消息封面
    }

    WX.invoke('shareToExternalChat', data, (res) => {
      if (res.err_msg == 'shareToExternalChat:ok') {
        resolve(res)
      } else {
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/90490#自定义转发到会话
// 自定义转发到会话
export async function shareAppMessage(data) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('shareAppMessage', data, (res) => {
      if (res.err_msg == 'shareAppMessage:ok') {
        resolve(res)
      } else {
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/90502
// 设置系统剪贴板的内容
export async function setClipboardData(text) {
  // await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.setClipboardData({
      data: String(text),
      success: resolve,
      fail: reject
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/93231
// 打开会话
// export async function openEnterpriseChat({
//   userIds,
//   externalUserIds,
//   groupName,
//   chatId
// }) {
//   await WXAgentRegister()
//   console.log('参数：', userIds, externalUserIds)
//   return new Promise((resolve, reject) => {
//     console.log('准备打开微信会话2')
//     WX.openEnterpriseChat(
//       Object.assign(
//         {
//           userIds,
//           externalUserIds,
//           groupName,
//           chatId
//         },
//         {
//           success: function (res) {
//             console.log('打开成功', res)
//             resolve(res)
//             // 回调
//           },
//           fail: function (res) {
//             console.log('打开失败', res)
//             reject(res)
//           }
//         }
//       )
//     )
//   })
// }

export async function openEnterpriseChat({
  userIds,
  externalUserIds,
  groupName,
  chatId
}) {
  // 判断是否是小程序环境
  // var ua = navigator.userAgent.toLowerCase();
  // if (ua.match(/MicroMessenger/i) == "micromessenger") {
  //   //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
  //   wx.miniProgram.getEnv((res) => {
  //     if (res.miniprogram) {
  //       // alert("在小程序里");
  //       consolole.log('在微信小程序里');
  //       opechartInApplet({
  //         userIds,
  //         externalUserIds,
  //         groupName,
  //         chatId
  //       })
  //       return
  //     }
  //   })
  // }
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    console.log('准备打开微信会话2')
    if (
      localStorage.getItem('chain_corp_id') &&
      localStorage.getItem('chain_corp_id') != '0'
    ) {
      createCorpGroupChat({
        groupName,
        userIds,
        externalUserIds
      })
    } else {
      WX.openEnterpriseChat({
        userIds,
        externalUserIds,
        groupName,
        chatId,
        success: function (res) {
          console.log('打开成功', res)
          resolve(res)
          // 回调
        },
        fail: function (res) {
          console.log('打开失败, 尝试小程序方式', res)
          reject(res)
        }
      })
    }
  })
}

// 打开会话企微小程序
export async function opechartInApplet({
  userIds,
  externalUserIds,
  groupName,
  chatId
}) {
  return new Promise((resolve, reject) => {
    console.log('准备打开微信群聊-小程序调用')
    if (
      localStorage.getItem('chain_corp_id') &&
      getItem('chain_corp_id') != '0'
    ) {
      createCorpGroupChatApplet({
        groupName,
        userIds,
        externalUserIds,
        chatId
      })
    } else {
      wx.qy.openEnterpriseChat({
        userIds,
        externalUserIds,
        groupName,
        chatId,
        success: function (res) {
          console.log('打开成功', res)
          resolve(res)
          // 回调
        },
        fail: function (res) {
          console.log('打开失败', res)
          reject(res)
        }
      })
    }
  })
}

// 创建企业互联/上下游会话接口 小程序
// https://developer.work.weixin.qq.com/document/path/94426
export async function createCorpGroupChatApplet({
  groupName,
  userIds,
  openUserIds,
  externalUserIds,
  corpGroupUserIds
}) {
  return new Promise((resolve, reject) => {
    if (typeof externalUserIds == 'string') {
      externalUserIds = externalUserIds.split(',')
    }
    console.log('创建上下游回话', {
      groupName,
      userIds,
      openUserIds,
      externalUserIds,
      corpGroupUserIds
    })
    wx.qy.createCorpGroupChat({
      groupName,
      userIds,
      openUserIds,
      externalUserIds,
      corpGroupUserIds,
      success: function (res) {
        // 回调
        // var chatId = res.chatId;
        resolve(res.chatId)
      },
      fail: (e) => {
        console.log('创建失败', e)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/94518
// 打开群聊并发送消息
export async function openExistedChatWithMsg({ chatId, msg }) {
  await isNeedConfig()
  await WXAgentRegister()
  console.log('参数', chatId, msg)
  return new Promise((resolve, reject) => {
    console.log('准备打开微信群聊')
    WX.invoke(
      'openExistedChatWithMsg',
      {
        chatId,
        msg
      },
      function (res) {
        if (res.err_msg == 'openExistedChatWithMsg:ok') {
          console.log('打开成功', res)
          resolve(res)
        } else {
          console.log('打开失败', res)
          reject(res)
        }
      }
    )
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/94958
// 发送客户朋友圈
export async function shareToExternalMoments({ text, attachments }) {
  await isNeedConfig()
  await WXAgentRegister()
  console.log('参数', text, attachments)
  return new Promise((resolve, reject) => {
    console.log('发送内容到客户朋友圈')
    WX.invoke(
      'shareToExternalMoments',
      {
        text,
        attachments
      },
      function (res) {
        console.log('resresres', res)
        if (res.err_msg == 'shareToExternalMoments:ok') {
          console.log('打开成功', res)
          resolve(res)
        } else {
          console.log('打开失败', res)
          reject(res)
        }
      }
    )
  })
}

// 选人
export async function selectEnterpriseContact({
  fromDepartmentId,
  mode,
  type,
  selectedDepartmentIds,
  selectedUserIds
}) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId,
        mode,
        type,
        selectedDepartmentIds,
        selectedUserIds
      },
      function (res) {
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result)
          }
          resolve(res)
        } else {
          reject(res)
        }
      }
    )
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/91823
// 外部联系人选人接口
export async function selectExternalContact() {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('selectExternalContact', { filterType: 0 }, function (res) {
      if (res.err_msg == 'selectExternalContact:ok') {
        // res.userIds
        resolve(res)
      } else {
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92722
// 获取进入H5页面的入口环境
export async function getContext() {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('getContext', {}, function (res) {
      console.log(
        'wx.agentConfigwx.agentConfigwx.agentConfigwx.agentConfig',
        res
      )
      if (res.err_msg == 'getContext:ok') {
        resolve(res.entry)
      } else {
        reject(res)
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/91799
// 获取当前外部联系人userid
export async function getCurExternalContact() {
  // 如果是上下游
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('getCurExternalContact', {}, function (res) {
      if (res.err_msg == 'getCurExternalContact:ok') {
        console.log('获取id成功---------------------', res)
        resolve(res.userId) //返回当前外部联系人userId
      } else {
        console.log('获取id失败---------------------', res)
        reject(res) //错误处理
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/91799
// 轮询请求外部联系人id (默认最多轮询4次)
// index： 轮询次数index
// times： 次数总数
export async function getCurExternalContactPolling(index = 0, times = 2) {
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    WX.invoke('getCurExternalContact', {}, function (res) {
      console.log('轮询获取id---------------------', res)
      if (res.err_msg == 'getCurExternalContact:ok') {
        resolve(res.userId) //返回当前外部联系人userId
      } else {
        if (index < times) {
          // setTimeout(() => {
          index++
          resolve(getCurExternalContactPolling(index, times))
          // }, delay)
        } else {
          console.log('轮询获取id失败---------------------', res)
          // reject(res)
          resolve('')
        }
      }
    })
  })
}

// https://developer.work.weixin.qq.com/document/path/95706
// 获取当前企业微信上下游通讯录联系人userid(上下游)
export async function getCurCorpGroupContact() {
  await isNeedConfig()
  await WXAgentRegister()
  // let res = await getContext()
  return new Promise((resolve, reject) => {
    WX.invoke('getCurCorpGroupContact', {}, function (res) {
      if (res.err_msg == 'getCurCorpGroupContact:ok') {
        console.log('获取上下游id成功---------------------', res)
        resolve(res.userId) //返回当前外部联系人userId
      } else {
        console.log('获取上下游id失败---------------------', res)
        reject(res) //错误处理
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92095
// 获取当前客户群的群ID
export async function getCurExternalChat() {
  // 如果是上下游
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('getCurExternalChat', {}, function (res) {
      if (res.err_msg == 'getCurExternalChat:ok') {
        resolve(res.chatId) //返回当前客户群的群聊ID
      } else {
        reject(res) //错误处理
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92095
// 轮询获取当前客户群的群ID
export async function getCurExternalChatPolling(index = 0, times = 2) {
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    WX.invoke('getCurExternalChat', {}, function (res) {
      console.log('轮询获取群id---------------------', res)
      if (res.err_msg == 'getCurExternalChat:ok') {
        resolve(res.chatId) //返回当前外部群id
      } else {
        if (index < times) {
          // setTimeout(() => {
          index++
          resolve(getCurExternalChatPolling(index, times))
          // }, delay)
        } else {
          console.log('轮询获取id失败---------------------', res)
          // reject(res)
          resolve('')
        }
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/92095
// 获取当前企业微信上下游通讯录联系群ID(上下游)
export async function getCurCorpGroupChat() {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke('getCurCorpGroupChat', {}, function (res) {
      if (res.err_msg == 'getCurCorpGroupChat:ok') {
        resolve(res.chatId) //返回当前客户群的群聊ID
      } else {
        reject(res) //错误处理
      }
    })
  })
}

// https://work.weixin.qq.com/api/doc/90000/90136/91789
// 聊天工具栏分享消息到会话
export async function sendChatMessage(content) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke(
      'sendChatMessage',
      { msgtype: 'text', text: { content } },
      function (res) {
        if (res.err_msg === 'sendChatMessage:ok') {
          resolve(res)
        } else {
          reject(res)
        }
      }
    )
  })
}
// https://work.weixin.qq.com/api/doc/90000/90136/91789
// 聊天工具栏分享各类消息到会话
// enterChat: 是否在分享消息后返回会话界面
export async function sendChatMessageAllType(
  msgtype,
  { data = {}, ...content },
  enterChat = true
) {
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise(async (resolve, reject) => {
    console.log('发送前参数', { msgtype, enterChat, ...content })
    const { poster_id, task_id, strategy_id, sale_script_id, source } = data
    let isSendable = true // 是否可发送

    // 存在海报id, 需要验证商品的上下架状态
    if (poster_id) {
      isSendable = await posterMediaDispose(data)
    }
    if (isSendable) {
      WX.invoke(
        'sendChatMessage',
        { msgtype, enterChat, ...content },
        function (res) {
          if (res.err_msg === 'sendChatMessage:ok') {
            res.sendStatus = true
          } else {
            res.sendStatus = false
          }
          console.log('发送结果：', res)
          // 存在海报id, 需要记录海报使用情况
          if (poster_id) {
            usePosterRecord({
              poster_id,
              source_id: task_id || strategy_id || sale_script_id || '',
              source_table: source
            })
          }
          resolve(res)
        }
      )
    } else {
      resolve({ sendStatus: false })
    }
  })
}

// 验证海报是否下架
async function posterMediaDispose({ poster_id, poster_info = {} }) {
  const { poster_name } = poster_info
  const { data = {} } = await checkGoodsStatus({ poster_id })
  let isSendable = true // 是否可发送
  if (data.is_shelves && String(data.is_shelves) !== '1') {
    isSendable = false
    Toast.clear()
    await Dialog.confirm({
      message: `${poster_name}商品海报已下架，是否继续发送？`
    })
      .then(() => {
        isSendable = true
      })
      .catch(() => {
        isSendable = false
      })
  }
  return isSendable
}

// https://work.weixin.qq.com/api/doc/90001/90144/93114
// 打开小程序
export async function openMiniProgram({ appid, path }) {
  await isNeedConfig()
  await WXAgentRegister()
  console.log('openMiniProgram参数: ', appid, path)
  return new Promise((resolve, reject) => {
    WX.invoke(
      'launchMiniprogram',
      {
        appid, // 需跳转的小程序appid
        path // 所需跳转的小程序内页面路径及参数。非必填
      },
      function (res) {
        if (res.err_msg == 'launchMiniprogram:ok') {
          // 正常
          console.log('launchMiniprogram:ok', res)
          resolve(res)
        } else {
          console.log('launchMiniprogram:error', res)
          // 错误处理
          reject(res)
        }
      }
    )
  })
}

// https://developer.work.weixin.qq.com/document/path/93864
// 快速跳转到添加客户界面
export async function navigateToAddCustomer() {
  console.log('添加好友')
  await isNeedConfig()
  await WXAgentRegister()
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  // uni.showLoading({title: '加载中', mask: true})
  return new Promise((resolve, reject) => {
    // wx.qy.navigateToAddCustomer({
    // 	success(res) {
    // 		// uni.hideLoading()
    //     Toast.clear()
    // 		resolve(res)
    // 	},
    // 	fail (e) {
    // 		// uni.hideLoading()
    //     Toast.clear()
    // 		console.log('error', e)
    // 	}
    // })
    WX.invoke('navigateToAddCustomer', {}, function (res) {
      Toast.clear()
      resolve(res)
    })
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/90524#%E6%89%93%E5%BC%80%E7%B3%BB%E7%BB%9F%E9%BB%98%E8%AE%A4%E6%B5%8F%E8%A7%88%E5%99%A8
// 打开默认浏览器（pc端）
export async function openNewWindow({ url }) {
  await isNeedConfig()
  await WXAgentRegister()

  return new Promise((resolve, reject) => {
    WX.invoke(
      'openDefaultBrowser',
      {
        url: url // 在默认浏览器打开redirect_uri，并附加code参数；也可以直接指定要打开的url，此时不会附带上code参数。
      },
      function (res) {
        if (res.err_msg != 'openDefaultBrowser:ok') {
          console.log('打开失败')
          location.href = url
          reject(res)
        } else {
          console.log('打开成功')
          window.open(url)
          resolve(res)
        }
      }
    )
  })
}

// https://work.weixin.qq.com/api/doc/90001/90144/90524#%E6%89%93%E5%BC%80%E7%B3%BB%E7%BB%9F%E9%BB%98%E8%AE%A4%E6%B5%8F%E8%A7%88%E5%99%A8
// 预览视频
export function previewFile({ url, name, size }) {
  // await WXAgentRegister()
  WX.previewFile({
    url: url, // 需要预览文件的地址(必填，可以使用相对路径)
    name: name, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
    size: size // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
  })
}

// https://developer.work.weixin.qq.com/document/path/90528
// 拍照或从手机相册中选图接口
export async function chooseImage(data = {}) {
  const {
    count = 1,
    sizeType = ['original', 'compressed'],
    sourceType = ['album', 'camera'],
    defaultCameraMode = 'batch',
    isSaveToAlbum = 1
  } = data
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    WX.chooseImage({
      count, // 默认1
      sizeType, // 可以指定是原图还是压缩图，默认二者都有
      sourceType, // 可以指定来源是相册还是相机，默认二者都有
      defaultCameraMode, //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
      isSaveToAlbum, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
      success: function (res) {
        console.log('选择图库', res)
        resolve(res.localIds)
        // var localIds = res.localIds; // 返回选定照片的本地ID列表，
        // andriod中localId可以作为img标签的src属性显示图片；
        // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
      },
      fail: function (e) {
        console.log('选择图库错误', e)
        reject(e)
      }
    })
  })
}

// 将本地资源上传到开发者服务器
export async function uploadImage(data = {}) {
  const { localId = '', isShowProgressTips = 1 } = data
  await isNeedConfig()
  await WXAgentRegister()
  return new Promise((resolve, reject) => {
    WX.uploadImage({
      localId, // 需要上传的图片的本地ID，由chooseImage接口获得
      isShowProgressTips, // 默认为1，显示进度提示
      success: function ({ serverId }) {
        // 返回图片的服务器端ID
        console.log('上传图片到企微', serverId)
        resolve(serverId)
      },
      fail: function (e) {
        console.log('上传图片到企微失败', e)
        reject(e)
      }
    })
  })
}

// 判断如果不是下游企业， 则调用wx.config
async function isNeedConfig() {
  const chainCorpId = localStorage.getItem('chain_corp_id')
  if (!chainCorpId || String(chainCorpId) === '0') {
    await WXRegister()
  }
  return Promise.resolve(true)
}
