<template>
  <van-nav-bar
    :safe-area-inset-top="true"
    :fixed="true"
    v-if="showNavigation"
    :title="title"
    :left-text="showTabbar ? '' : '返回'"
    :left-arrow="!showTabbar"
    @click-left="onClickLeft"
  />
  <div class="content" :class="{ showNavigation, showTabbar }" id="content">
    <router-view v-slot="{ Component }">
      <keep-alive :include="cachePageName" :max="10">
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
  </div>
  <van-tabbar route v-if="showTabbar">
    <van-tabbar-item
      v-for="tabbar in tabbars"
      :key="tabbar.name"
      replace
      :to="tabbar.path"
      :icon="title == tabbar.name ? tabbar.acicon : tabbar.icon"
    >
      <span>{{ tabbar.name }}</span>
      <template #icon="props">
        <!-- <van-image
          width="24"
          height="24"
          fit="fill"
          
          style="width: 24px; height: 24px !important"
          :src="
            props.active
              ? require('@/assets/img/menu_icon/' + tabbar.acicon)
              : require('@/assets/img/menu_icon/' + tabbar.icon)
          "
        /> -->
        <img
          class="main-menu-icon"
          :src="
            props.active
              ? require('@/assets/img/menu_icon/' + tabbar.acicon)
              : require('@/assets/img/menu_icon/' + tabbar.icon)
          "
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem, Image } from 'vant'
import { NavBar } from 'vant'
import {
  toRefs,
  reactive,
  computed,
  onMounted,
  onUpdated,
  ref,
  watch
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { store } from '@/store'

export default {
  name: 'Layout',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    [Image.name]: Image
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const data = reactive({
      title: '',
      showNavigation: true,
      showTabbar: true
    })
    const tabbars = [
      // { name: '首页', path: '/workbench', icon: 'home-o' },
      // { name: '客户', path: '/customer', icon: 'manager-o' },
      // { name: '社群', path: '/community', icon: 'bullhorn-o' },
      // { name: '工具', path: '/tool', icon: 'apps-o' }
      {
        name: '首页',
        path: '/workbench',
        icon: 'home_inactive.svg',
        acicon: 'home_active.svg'
      },
      {
        name: '任务',
        path: '/task',
        icon: 'task_inactive.svg',
        acicon: 'task_active.svg'
      },
      {
        name: '客户',
        path: '/customer',
        icon: 'customer_inactive.svg',
        acicon: 'customer_active.svg'
      },
      {
        name: '客户群',
        path: '/community',
        icon: 'customer_g_inactive.svg',
        acicon: 'customer_g_active.svg'
      }
    ]

    // 返回上一页
    const onClickLeft = () => {
      // console.log('触发------------1111')

      router.back(-1)
    }
    // 更新当前页面标题栏及底部导航栏状态
    const updateNavigation = () => {
      data.title = route.meta.title
      data.showNavigation = route.meta.hiddenNavigation ? false : true
      data.showTabbar = Boolean(
        tabbars.filter((tabbar) => tabbar.path == route.path).length
      )
    }

    onMounted(() => {
      // console.log(iii)
      console.log('onMounted')
      setTimeout(() => {
        if (
          document.getElementById('content') &&
          document.getElementById('content').scrollTop
        ) {
          document.getElementById('content').scrollTop = 0
        }
      }, 10)
      updateNavigation()
    })

    onUpdated(() => {
      updateNavigation()
    })

    return {
      onClickLeft,
      ...toRefs(data),
      tabbars
    }
  },
  computed: {
    cachePageName() {
      return store.state.cachePages.cachePageName
    }
  }
}
</script>
<style lang="less">
.content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  &.showNavigation {
    margin-top: 46px;
    box-sizing: border-box;
    height: calc(100vh - 46px);
  }
  &.showTabbar {
    padding-bottom: 50px;
  }

  // .van-tabbar-item__icon img {
  //   height: 24px !important;
  // }
}

.main-menu-icon {
  width: 24px;
  height: 24px !important;
}
</style>
