const state = {
  list: [
    {
      name:
        'YOGOJST金枕头冷冻有籽榴莲果肉新鲜速冻水果300g盒装【超定制YOGOJST金枕头冷冻有籽榴莲果肉新鲜速冻水果300g盒装】',
      img:
        'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1deba1f6aa014ae69442a8263220be98.jpeg',
      type: '水果',
      unit: '份',
      stock: 10,
      reset: 3,
      price: '49.9',
      now_price: '34.9',
      id: new Date().getTime()
    },
    {
      name: '【今日特价】A级-密刺黄瓜250g',
      img:
        'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1deba1f6aa014ae69442a8263220be98.jpeg',
      type: '水果',
      unit: '盒',
      stock: 10,
      reset: 3,
      price: '3.38',
      now_price: '3.21',
      id: new Date().getTime() + 10
    }
  ]
}
const mutations = {
  SET_LIST: (state, data) => {
    if (Array.isArray(data)) {
      state.list = data
    } else {
      const index = state.list.findIndex((item) => item.id === data.id)
      if (index != -1) {
        state.list[index] = data
      } else {
        state.list.push(data)
      }
    }
  },
  DEL_LIST: (state, id) => {
    const index = state.list.findIndex((item) => item.id === id)
    if (index != -1) {
      state.list.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
