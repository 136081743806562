//微信api能力
export const jsApiList = [
  'onMenuShareAppMessage',
  'onMenuShareTimeline',
  'updateAppMessageShareData',
  'shareAppMessage',
  'chooseImage',
  'uploadImage',
  'getLocalImgData',
  'selectEnterpriseContact',
  'setClipboardData',
  'previewImage',
  'openEnterpriseChat',
  'shareToExternalContact',
  'sendChatMessage',
  'getCurExternalContact',
  'selectExternalContact',
  'getContext',
  'createCorpGroupChat',
  'getCurExternalChat',
  'shareToExternalChat',
  'launchMiniprogram',
  'openDefaultBrowser',
  'openExistedChatWithMsg',
  'shareToExternalMoments',
  'navigateToAddCustomer'
]
//微信分享默认文案
export const wxShareInfo = {
  title: '微信分享标题',
  desc: '微信分享描述',
  type: '',
  dataUrl: '',
  imgUrl: '',
  link: ''
}
//应用id
export const AgentId = Number(process.env.VUE_APP_AGENT_ID)
console.log('AgentId:', process.env.VUE_APP_AGENT_ID)

export const corpid = process.env.VUE_APP_CORP_ID

// 分享类型
export const shareType = {
  group: {
    label: '分享至我的客户群'
  },
  personal: {
    label: '分享至我的客户'
  }
}

//客户类型 1 企业微信好友 2 微信好友
export const customerType = {
  1: '企业微信',
  2: '微信'
}

// 客户来源
export const sourceEnum = {
  0: '未知来源',
  1: '扫描二维码',
  2: '搜索手机号',
  3: '名片分享',
  4: '群聊',
  5: '手机通讯录',
  6: '微信联系人',
  7: '来自微信的添加好友申请',
  8: '安装第三方应用时自动添加的客服人员',
  9: '搜索邮箱',
  201: '内部成员共享',
  202: '管理员/负责人分配'
}

// 客户状态
export const clientStatuEnum = {
  1: '互为好友关系',
  2: '成员删除客户',
  3: '客户删除成员'
}

// 页面通信常量
export const EVENTCONST = {
  CHANGESTATUS: 'changeStatus',
  SENDTEXT: 'sendText',
  LOADYZLIST: 'loadYzList',
  ISYOUZAN: 'isYouZan',
  ISUPDATELABEL: 'isupdateLabel',
  LOADASFLIST: 'loadAsfList',
  ISAOSIFEN: 'isAosiFen',
  ISALLOTADD: 'isAllotAdd'
}

// 默认头像
export const defaultAvatar =
  'http://we-customer-api.freshgood.cn/upload/source_2022/01/26/fiRGqmzI8Dv4KbuSnTsNkOYQAL19VW7r.png'
