<template>
  <div class="customer-analysis">
    <div class="customer-analysis-title">客户分析</div>
    <div class="customer-analysis-row">
      <div class="item">
        <div class="num">{{ addNum }}</div>
        <div class="label">今日新增客户数</div>
      </div>
      <div class="item">
        <div class="num">{{ tagNum }}</div>
        <div class="label">今日已打标签客户数</div>
      </div>
    </div>
    <div class="customer-analysis-row">
      <div class="item">
        <div class="num">{{ touchNum }}</div>
        <div class="label">今日已触达客户数</div>
      </div>
      <div class="item">
        <div class="num">{{ registerNum }}</div>
        <div class="label">今日注册会员数</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { getCustomerAnalysis } from '../../service/customer.service'

export default {
  computed: {
    [Notify.name]: Notify
  },
  data() {
    return {
      addNum: null,
      tagNum: null,
      touchNum: null,
      registerNum: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.initData()
    })
  },
  methods: {
    // 初始化客户分析数据
    async initData() {
      try {
        const {
          addNum,
          tagNum,
          touchNum,
          registerNum
        } = await getCustomerAnalysis()
        this.addNum = addNum
        this.tagNum = tagNum
        this.touchNum = touchNum
        this.registerNum = registerNum
      } catch (e) {
        console.log('客户分析数据获取失败！', e)
        // Notify({ type: 'danger', message: '客户分析数据获取失败！' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.customer-analysis {
  margin: 8px;
  background: @white;
  border-radius: 5px;

  & > &-title {
    display: inline-block;
    margin: 8px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid @blue;
  }

  &-row {
    display: flex;
    padding: 12px 0;
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
    &:last-of-type {
      border-bottom: none;
    }

    .item {
      flex: 1;
      text-align: center;

      > .label {
        padding-top: 6px;
        color: @gray-6;
        font-size: 12px;
      }
    }
  }
}
</style>
