<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="cachePageName" :max="10">
      <component
        :is="Component"
        :key="$route.name"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive>
    <component
      :is="Component"
      :key="$route.name"
      v-if="!$route.meta.keepAlive"
    />
  </router-view>
</template>

<script>
import { ref, nextTick } from 'vue'
import { store } from '@/store'

export default {
  provide() {
    return {
      $refresh: this.refresh
    }
  },
  setup() {
    const isRouterAlive = ref(true)
    const refresh = async () => {
      isRouterAlive.value = false
      await nextTick()
      isRouterAlive.value = true
    }
    return {
      isRouterAlive,
      refresh
    }
  },
  computed: {
    cachePageName() {
      console.log('vuex数据', store.state.cachePages.cachePageName)
      return store.state.cachePages.cachePageName
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
}
</style>
