export default {
  data() {
    return {}
  },
  methods: {
    // 格式化数字, 存在小数则最多保留两位小数
    formatNum(value) {
      return value ? (Math.round(value * 100) / 100).toString() : 0
    },
    // 文字超出省略 num+1: 前后保留多少位 frontNum: 前面位数 behindNum: 后面位数
    ellipsisText(value, frontNum = 4, behindNum) {
      if (!value) return ''
      if (
        behindNum
          ? value.length > frontNum + behindNum + 1
          : value.length > 2 * frontNum + 1
      ) {
        let arr = value.split('')
        const newArr = [
          ...arr.slice(0, frontNum),
          '...',
          ...arr.slice(arr.length - (behindNum || frontNum), arr.length)
        ]
        return newArr.join('')
      } else {
        return value
      }
    },
    // 获取用户性别图标
    getUserSexIcon(type) {
      const man = ['男', 'man', '1', 1]
      const women = ['女', 'woman', '2', 2]

      if (!type) return ''
      if (man.includes(type)) {
        return require('@/assets/img/common/man.png')
      } else if (women.includes(type)) {
        return require('@/assets/img/common/women.png')
      } else {
        return ''
      }
    },
    // 手机号敏感处理
    mobileSensitivity(val) {
      if (!val) return ''
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return val.replace(reg, '$1****$2')
    }
  }
}
