<template>
  <div class="hot-material-item">
    <div class="item-classes">
      <span>{{ data.title }}</span>
      <span>更新时间：{{ data.updateTime }}</span>
    </div>
    <div class="item-container">
      <!-- 图片 -->
      <van-image
        v-if="data.imageURL"
        width="50"
        height="50"
        :src="data.imageURL"
      />
      <div class="content">
        <div class="text">「{{ data.parent }}」</div>
        <div class="text">{{ data.content }}</div>
      </div>
      <!-- 分享 -->
      <van-button
        type="primary"
        size="small"
        :disabled="!data.share"
        @click="handleShare"
      >
        分享
      </van-button>
    </div>
  </div>
</template>

<script>
import { Image, Button } from 'vant'

export default {
  components: {
    [Image.name]: Image,
    [Button.name]: Button
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleShare() {
      this.$emit('share')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.hot-material-item {
  padding: 8px;
  font-size: 12px;
  border-bottom: thin solid @gray-4;
  &:last-of-type {
    border-bottom: none;
  }

  > .item-classes {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
  }

  > .item-container {
    display: flex;
    align-items: center;
    > .van-image {
      margin-right: 6px;
    }
    > .content {
      flex: 1;
      align-self: stretch;
      > .text {
        padding: 0 6px 6px 0;
        &:last-of-type {
          padding: 0 6px 0 0;
        }
      }
    }
  }
}
</style>
